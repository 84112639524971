import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import ImageGallery from './components/ImageGallery'
import AboutPage from './components/AboutPage'
import ContactPage from './components/ContactPage'
import frontImage from './assets/images/Frontal camisa.png'
import backImage from './assets/images/Trasera camisa.png'
import modelImage1 from './assets/images/IMG_2564.jpg'
import modelImage2 from './assets/images/IMG_2544.jpg'
import modelImage3 from './assets/images/IMG_2540.jpg'

function ProductPage() {
  const handleRequestPurchase = (e) => {
    e.preventDefault();
    window.location.href = 'mailto:foirola29640@gmail.com?subject=Solicitud%20Camiseta%20El%20Mesías&body=Hola,%20me%20gustaría%20solicitar%20la%20camiseta%20El%20Mesías.%20Por%20favor,%20envíenme%20más%20información.';
  };

  const images = [
    { src: modelImage2, alt: "Modelo con la camiseta" },
    { src: modelImage3, alt: "Modelo con la camiseta" },
    { src: modelImage1, alt: "Modelo con la camiseta" },
    { src: frontImage, alt: "Frontal de la camiseta" },
    { src: backImage, alt: "Trasera de la camiseta" }
  ];

  return (
    <div className="max-w-6xl mx-auto">
      <div className="text-center mb-5">
        <h1 className="text-3xl font-bold text-gray-900">El Mesías</h1>
        <p className="mt-2 text-lg text-gray-600">Camiseta de edición limitada</p>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <ImageGallery images={images} />

        <div className="space-y-4">
          <div>
            <h2 className="text-xl font-bold text-gray-900">¿Quién es el Mesenjero de Dios?</h2>
            <p className="mt-2 text-gray-600">
              Muy pronto viene el fin de la cosa... El día de juicio está cercando.
              Camiseta de edición limitada con diseño único que celebra la cultura local de Fuengirola.
            </p>
          </div>

          <div className="border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-gray-900">Detalles</h3>
            <div className="mt-2 space-y-2">
              <p className="text-sm text-gray-600">• Camiseta 100% algodón de calidad premium</p>
              <p className="text-sm text-gray-600">• Diseño único impreso en España</p>
              <p className="text-sm text-gray-600">• Disponible en varias tallas</p>
              <p className="text-sm text-gray-600">• Edición limitada</p>
            </div>
          </div>

          <div className="border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-gray-900">Cómo conseguirla</h3>
            <p className="mt-2 text-gray-600">
              Esta es una edición limitada. Para solicitar tu camiseta, haz clic en el botón
              de abajo para enviarnos un correo. Te responderemos con la disponibilidad y los detalles de pago.
            </p>
          </div>

          <button
            onClick={handleRequestPurchase}
            className="w-full bg-gray-900 text-white px-6 py-3 rounded-md hover:bg-gray-800 transition-colors duration-200"
          >
            Solicitar por Email
          </button>

          <p className="text-sm text-gray-500 text-center">
            Cantidades limitadas disponibles
          </p>

          <div className="border-t border-gray-200 pt-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Guía de Tallas</h3>
            <p className="text-sm text-gray-600 mb-4">
              Consulta las medidas de nuestras camisetas para encontrar la talla perfecta:
            </p>
            <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead>
                  <tr>
                    <th className="border border-gray-200 px-4 py-2 bg-gray-50 text-gray-900">Talla</th>
                    <th className="border border-gray-200 px-4 py-2 bg-gray-50 text-gray-900">Ancho (A) cm</th>
                    <th className="border border-gray-200 px-4 py-2 bg-gray-50 text-gray-900">Largo (B) cm</th>
                    <th className="border border-gray-200 px-4 py-2 bg-gray-50 text-gray-900">Manga (C) cm</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">S</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">51.5</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">71</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">22.5</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">M</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">55.5</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">75</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">24</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">L</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">58.5</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">77</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">24.5</td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">XXL</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">65.5</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">81</td>
                    <td className="border border-gray-200 px-4 py-2 text-gray-600">25.5</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p className="text-xs text-gray-500 mt-2">
              * Las medidas son aproximadas y pueden variar ligeramente.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<ProductPage />} />
          <Route path="/sobre-nosotros" element={<AboutPage />} />
          <Route path="/contacto" element={<ContactPage />} />
        </Routes>
      </Layout>
    </Router>
  );
}