import React from 'react';
import { Link } from 'react-router-dom';

export default function Layout({ children }) {
  return (
    <div className="min-h-screen bg-white text-gray-900 font-sans flex flex-col">
      <header className="py-3 px-4 sm:px-6 lg:px-8 border-b border-gray-200 sticky top-0 bg-white z-50">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <Link to="/">
              <img
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/FOIROLA_Logo_LOGO-BYa7fAo1Qnj6q7nk5ej7gwykI7U3W3.png"
                alt="Foirola"
                className="h-8 w-auto"
              />
            </Link>
          </div>
          <nav className="flex items-center space-x-6">
            <Link to="/" className="text-sm font-medium text-gray-700 hover:text-gray-900">Producto</Link>
            <Link to="/sobre-nosotros" className="text-sm font-medium text-gray-700 hover:text-gray-900">Sobre Nosotros</Link>
            <Link to="/contacto" className="text-sm font-medium text-gray-700 hover:text-gray-900">Contacto</Link>
          </nav>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 flex-grow">
        {children}
      </main>

      <footer className="bg-gray-50 border-t border-gray-200">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} Foirola. Todos los derechos reservados.
          </div>
        </div>
      </footer>
    </div>
  );
} 