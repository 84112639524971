import React from 'react';

export default function AboutPage() {
  return (
    <div className="max-w-3xl mx-auto text-center py-12">
      <h2 className="text-3xl font-bold text-gray-900 mb-6">Sobre Nosotros</h2>
      <p className="text-lg text-gray-600">
        Tres zumbaos dándole voz al lore de nuestra tierra. A través de nuestros esfuerzos creativos, 
        aspiramos a mostrar el corazón y el alma de Fuengirola, dando vida a sus historias de maneras 
        emocionantes e inesperadas. Siempre desde el humor y la parodia.
      </p>
    </div>
  );
} 