import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (!isModalOpen) return;
      
      if (e.key === 'Escape') {
        setIsModalOpen(false);
      } else if (e.key === 'ArrowLeft') {
        setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
      } else if (e.key === 'ArrowRight') {
        setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [isModalOpen, images.length]);

  const handleSwipe = (direction) => {
    if (direction === 'Left') {
      setCurrentImageIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    } else if (direction === 'Right') {
      setCurrentImageIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe('Left'),
    onSwipedRight: () => handleSwipe('Right'),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 10, // minimum distance before a swipe is registered
    swipeDuration: 500, // maximum time allowed to complete a swipe
  });

  return (
    <div className="relative">
      <div className="space-y-2">
        {/* Main image */}
        <div 
          className="relative pb-[100%] overflow-hidden cursor-pointer"
          onClick={() => setIsModalOpen(true)}
          {...swipeHandlers}
        >
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="absolute inset-0 w-full h-full object-contain hover:scale-105 transition-transform duration-200"
          />
        </div>

        {/* Thumbnails */}
        <div className="grid grid-cols-4 gap-2">
          {images.map((image, index) => (
            <div 
              key={index}
              className={`relative pb-[100%] overflow-hidden cursor-pointer ${
                currentImageIndex === index ? 'ring-2 ring-gray-900' : ''
              }`}
              onClick={() => setCurrentImageIndex(index)}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="absolute inset-0 w-full h-full object-contain hover:scale-105 transition-transform duration-200"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50"
          onClick={() => setIsModalOpen(false)}
          {...swipeHandlers}
        >
          <img
            src={images[currentImageIndex].src}
            alt={images[currentImageIndex].alt}
            className="max-h-[90vh] max-w-[90vw] object-contain"
          />
          <button
            className="absolute top-4 right-4 text-white text-2xl"
            onClick={() => setIsModalOpen(false)}
          >
            ×
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery; 